import React, { useState, useEffect } from "react";
import moment from "moment";
import { useBoolean } from "ahooks";
import { observer } from "mobx-react-lite";
import {
  Row,
  Typography,
  Col,
  Spin,
  message,
  Divider,
  Dropdown,
  Menu,
  Button,
  Drawer,
  Checkbox,
  Popover,
  Form,
} from "antd";
import { Auth } from "aws-amplify";
import {
  PlusOutlined,
  UploadOutlined,
  EditOutlined,
  EllipsisOutlined,
  QuestionCircleOutlined,
  DeleteOutlined,
  WarningOutlined,
  UpOutlined,
  DownOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import SheetFilesModal from "./SheetFilesModal";
import {
  StyledButton,
  CommonTable,
  TableCommonMenu,
  FormWrapper,
} from "./../../../shared/commonStyles";
import { calculateAirDistance } from "../../../services/dataSheet";
import { SurveyNotesDrawer } from "./SurveyNotesDrawer";
import { userInfoStore } from "../../Carbon/UserInfoStore";
import { useLocation } from "react-router-dom";
import { DynamicCell } from "./DynamicCell";
import { useSize } from "ahooks";
import { surveyStore } from "../../../stores/SurveyStore";
import styles from "./SurveyView.module.scss";
const { Title } = Typography;

import "./style.css";
import { SurveyFooterButtons } from "./SurveyFooterButtons";
import { NotesDrawer } from "../../dataSheetsPage/dataSheetDocumentsPage/Components/NotesDrawer";
import MassUploadDataSheet from "../../dataSheetsPage/dataSheetDocumentsPage/Components/MassUploadDataSheet";

interface DataSheetMultiLineFormProps {
  validationIssuesId: string;
  onClickPrevious: () => void;
  onClickNext: (isSkipped?: boolean) => void;
  completeSurvey: () => Promise<void>;
  openInfoDrawer: () => void;
  handleUpdateTaskDeployment: (status: string) => void;
}

interface MultilineFieldNote extends FieldNote {
  line?: number;
  recordId?: string;
}

export const DataSheetMultiLineForm = observer(function DataSheetMultiLineForm({
  validationIssuesId,
  onClickPrevious,
  onClickNext,
  completeSurvey,
  openInfoDrawer,
  handleUpdateTaskDeployment,
}: DataSheetMultiLineFormProps) {
  const { state } = useLocation();
  const [pagination, setPagination] = useState<Pagination>({
    current: 1,
    pageSize: 10,
  });
  const [dataSheetRecords, setDataSheetRecords] = useState<DataSheetRecord[]>(
    []
  );
  const [disabledFields, setDisabledFields] = useState<any[]>([]);
  const [newData, setNewData] = useState<DataSheetRecord>(
    {} as DataSheetRecord
  );
  const [data, setData] = useState<DataSheetRecord | null>(null);
  const [notes, setNotes] = useState<Note[]>();
  const [showDataSheetFilesModal, setShowDataSheetFilesModal] =
    useState<boolean>(false);
  const [count, setCount] = useState(0);
  const [
    isNotesDrawerShown,
    { setTrue: showNotesDrawer, setFalse: hideNotesDrawer },
  ] = useBoolean(false);

  const size = useSize(document.querySelector("body"));
  const [showAddNewDrawer, setShowAddNewDrawer] = useState(false);
  const [isAllCommentsShown, { toggle: toggleAllComments }] = useBoolean(false);
  const [activeNoteRecord, setActiveNoteRecord] = useState<{
    recordId: string;
    noteType?: "comment" | "warning" | "estimate" | "estimatedBoolean";
  }>({
    recordId: "",
  });

  useEffect(() => {
    updateDataSheetRecords();
    setPagination({
      ...pagination,
      total: surveyStore.dataSheetRecords?.length,
    });
  }, [surveyStore.currentStep]);

  useEffect(() => {
    isAirDistanceSheet() && checkDisabledFields();
    const filteredData = dataSheetRecords?.filter(
      (v) => v.sheet_type === "new" || v.sheet_type === "edited"
    );
    surveyStore.changeSurveyData(filteredData, "dataToSave");
    setNotes(dataSheetRecords[0]?.notes);
  }, [dataSheetRecords]);

  const updateDataSheetRecords = () => {
    if (!surveyStore.dataSheet?.sheet_schema) {
      return;
    }
    const schemas = {} as SheetSchema;
    surveyStore.dataSheet?.sheet_schema?.forEach((schema) => {
      schemas[schema.entity_name] = schema.unit_id as string;
    });

    const tempRecords = surveyStore.dataSheetRecords?.map(
      (record: DataSheetRecord) => {
        const records = record[record.sheet_name];
        let temp: DataSheetRecord = {} as DataSheetRecord;
        Object.keys(records || {}).forEach((key) => {
          if (schemas[key]) {
            temp[`${key}_unit_id`] = schemas[key] as any;
          }
        });

        temp = {
          ...temp,
          ...records,
        } as DataSheetRecord;
        setCount(count + 1);
        return {
          ...record,
          [record.sheet_name]: temp,
          key: record.key || uuidv4(),
        };
      }
    );

    setDataSheetRecords(tempRecords as DataSheetRecord[]);
  };

  const isAirDistanceSheet = () => {
  const requiredFields = ["destination_city", "origination_city", "distance", "routing"];
  
  // Ensure entityNames is an array, even if sheet_schema is empty or undefined
  const entityNames = surveyStore.dataSheet?.sheet_schema
    ? surveyStore.dataSheet.sheet_schema.map((v: SheetSchema) => v.entity_name)
    : [];

  const hasRequiredFields = requiredFields.every((field) => entityNames.includes(field));
  const hasJourneyField =
    entityNames.includes("type_of_journey") || entityNames.includes("journey_type");

  return hasRequiredFields && hasJourneyField;
  };
  const checkDisabledFields = () => {
    const disabledFieldsArray = [] as any;
    dataSheetRecords.forEach((record: any) => {
      if (
        !!record[record.sheet_name].destination_city &&
        !!record[record.sheet_name].origination_city &&
        (!!record[record.sheet_name].type_of_journey || !!record[record.sheet_name].journey_type) &&
        (!!record[record.sheet_name].distance_actual_value ||
          !!record[record.sheet_name].distance)
      ) {
        disabledFieldsArray.push({
          id: record._id?.$oid || record.key,
          disabledFields: [
            "routing",
            "distance",
            "distance_actual_value",
            "distance_unit_id",
          ],
        });
      }
    });

    setDisabledFields(disabledFieldsArray);
  };

  const onClickAddNewRecord = async () => {
    if (!surveyStore.dataSheet) {
      return;
    }
    setPagination({
      ...pagination,
      pageSize: pagination.pageSize + 1,
      total: (pagination?.total || 0) + 1,
    });
    const schemas = {} as SheetSchema;
    surveyStore.dataSheet?.sheet_schema
      ?.filter(
        (schema) => !schema.isExpression && schema.input_type !== "expression"
      )
      .forEach((schema) => {
        schemas[schema.entity_name] = "";
        if (
          schema?.metadata_entity_name &&
          surveyStore.surveyInfo?.defaultMetaDataSheetId?.length
        ) {
          schemas[schema.entity_name] =
            surveyStore.defaultMetaDataRecord?.[schema?.metadata_entity_name];
        }
        if (schema.unit_id) {
          schemas[schema.entity_name + "_actual_value"] = "";
          schemas[schema.entity_name + "_unit_id"] = schema.unit_id;
        }
        if (
          surveyStore.currentStepInfo?.placeholders &&
          Object.keys(surveyStore.currentStepInfo?.placeholders || {})?.length
        ) {
          const schemaPlaceholder = Object.keys(
            surveyStore.currentStepInfo?.placeholders || {}
          )?.find((field) => field === schema.entity_name);
          if (schemaPlaceholder) {
            if (schema?.data_type === "date") {
              (schemas as any)[schema.entity_name] = surveyStore.currentStepInfo
                ?.placeholders[schema.entity_name]
                ? moment(
                    (
                      surveyStore.currentStepInfo?.placeholders[
                        schema.entity_name
                      ] as any
                    )?.$date ||
                      surveyStore.currentStepInfo?.placeholders[
                        schema.entity_name
                      ]
                  )
                : undefined;
            } else {
              if (schema.unit_id) {
                schemas[schema.entity_name + "_actual_value"] =
                  typeof schema.entity_name === "object"
                    ? undefined
                    : surveyStore.currentStepInfo?.placeholders[
                        schema.entity_name
                      ];
                schemas[schema.entity_name + "_unit_id"] =
                  typeof schemaPlaceholder[0] === "object"
                    ? undefined
                    : surveyStore.currentStepInfo?.placeholders[
                        schema.entity_name + "_unit_id"
                      ];
                schemas[schema.entity_name + "_actual_unit_id"] =
                  typeof schemaPlaceholder[0] === "object"
                    ? undefined
                    : surveyStore.currentStepInfo?.placeholders[
                        schema.entity_name + "_unit_id"
                      ];
              }
              schemas[schema.entity_name] =
                typeof surveyStore.currentStepInfo?.placeholders[
                  schema.entity_name
                ] === "object"
                  ? undefined
                  : surveyStore.currentStepInfo?.placeholders[
                      schema.entity_name
                    ];
            }
          }
        }
      });
    // DataSheetRecord type
    const newObject: DataSheetRecord = {} as DataSheetRecord;
    const data: any = await Auth.currentSession();
    const group = data.accessToken.payload["cognito:groups"].filter(
      (element: any) => element.includes("org:")
    )[0];
    const username = data.accessToken.payload.username;
    const sheetName = surveyStore.dataSheet?.sheet_name;
    const sheetId = surveyStore.dataSheet?._id.$oid;
    newObject.sheet_id = { $oid: sheetId };
    newObject.sheet_name = sheetName;
    newObject.sheet_type = "new";
    newObject.group_id = group;
    newObject.username = username;
    newObject.thresholds = {} as Threshold;
    newObject.key = uuidv4();
    newObject[surveyStore.dataSheet?.sheet_name] = schemas;
    setCount(count + 1);

    const newArray = [newObject, ...dataSheetRecords];
    setDataSheetRecords(newArray);

    size && size?.width <= 900 && setShowAddNewDrawer(true);
  };

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination);
  };

  const onClickDataSheetFileBtn = (record: DataSheetRecord) => {
    setData(record);
    setShowDataSheetFilesModal(true);
  };

  const onClickNotesBtn = (record: DataSheetRecord) => {
    setData(record);
    showNotesDrawer();
  };

  const onClickDeleteBtn = (record: DataSheetRecord) => {
    setPagination({
      ...pagination,
      pageSize: pagination.pageSize - 1,
      total: (pagination?.total || 0) - 1,
    });

    const newArray = dataSheetRecords?.filter(
      (item) => item.key !== record.key
    );
    setDataSheetRecords(newArray);
  };

  const handleAddNote = (note: string) => {
    const formattedNote = {
      user: userInfoStore.userName,
      date: moment().format("YYYY-MM-DD HH:mm:ss"),
      text: note,
    };
    let tempNotes = [...(notes || [])];
    tempNotes.push(formattedNote);

    const sheetType =
      dataSheetRecords[0].sheet_type !== "new" ? "edited" : "new";
    const newRecord = {
      ...dataSheetRecords[0],
      sheet_type: sheetType,
      notes: tempNotes,
    };
    const newArray = dataSheetRecords.map((rec) => {
      if (rec?.key === newRecord?.key) {
        return newRecord;
      } else return rec;
    });
    setDataSheetRecords(newArray as DataSheetRecord[]);
    setNotes(tempNotes);
    setData(newArray[0] as DataSheetRecord);
    // hideNotesDrawer();
  };

  const moreButtons = (record: DataSheetRecord) => {
    return (
      <TableCommonMenu>
        <Menu.Item key="files">
          <StyledButton
            disabled={
              record.isLocked || surveyStore.surveyInfo?.status !== "ACTIVE"
            }
            type="default"
            onClick={() => onClickDataSheetFileBtn(record)}
          >
            <UploadOutlined /> Upload file
          </StyledButton>
        </Menu.Item>
        {surveyStore.currentStepInfo.show_notes && (
          <Menu.Item key="notes">
            <StyledButton
              disabled={record.isLocked}
              type="default"
              onClick={() => onClickNotesBtn(record)}
            >
              <EditOutlined /> Add notes
            </StyledButton>
          </Menu.Item>
        )}{" "}
        {record.sheet_type === "new" && (
          <Menu.Item key="delete">
            <StyledButton
              type="default"
              onClick={() => onClickDeleteBtn(record)}
            >
              <DeleteOutlined /> Delete record
            </StyledButton>
          </Menu.Item>
        )}
      </TableCommonMenu>
    );
  };

  const filteredSchemas = surveyStore.dataSheet?.sheet_schema?.filter(
    (schema) =>
      !schema.isExpression &&
      schema.input_type !== "expression" &&
      (surveyStore.currentStepInfo.display_fields
        ? surveyStore.currentStepInfo.display_fields?.find(
            (item: any) => item === schema.entity_name
          )
        : !surveyStore.currentStepInfo?.hide_fields &&
          !schema?.metadata_entity_name)
  );
  const airDistanceCalculate = (record: DataSheetRecord) => {
    const isAllRequaredFiedsFilled =
      !!record[record.sheet_name].destination_city &&
      !!record[record.sheet_name].origination_city &&
      (!!record[record.sheet_name].type_of_journey || !!record[record.sheet_name].journey_type);
    if (isAllRequaredFiedsFilled) {
      let payload: any = {
        origin: record[record.sheet_name].origination_city,
        destination: record[record.sheet_name].destination_city,
        trip_type: record[record.sheet_name].type_of_journey || record[record.sheet_name].journey_type,
      };
      !!record[record.sheet_name].transit_city &&
        (payload.transit = record[record.sheet_name].transit_city);

      calculateAirDistance(payload).then((result) => {
        let newRecord = record;
        newRecord[record.sheet_name] = {
          ...record[record.sheet_name],
          routing: result.routing,
          distance: result.distance,
          distance_actual_value: result.distance,
          distance_actual_unit_id: "km",
          distance_unit_id: "km",
        };

        const newDataSheetRecords = dataSheetRecords.map((v) => {
          if (v._id?.$oid == record._id?.$oid || v.key === record.key) {
            return newRecord;
          } else {
            return v;
          }
        });

        setDataSheetRecords(newDataSheetRecords);
      });
    }
  };
  const handleSave = async (record: DataSheetRecord, sheetId: string) => {
    const newRow = record;

    isAirDistanceSheet() && airDistanceCalculate(record);
    if (newRow.sheet_type !== "new") {
      newRow.sheet_type = "edited";
    }
    setDataSheetRecords((prev) =>
      prev.map((rec) => {
        if (newRow.sheet_type === "new") {
          return rec.key === record.key ? newRow : rec;
        }
        delete newRow.key;
        return rec._id?.$oid === sheetId ? newRow : rec;
      })
    );
  };

  const handleAddRecord = (record: DataSheetRecord, sheetId: string) => {
    setNewData(record);
  };

  const saveNewRecord = () => {
    handleSave(newData, dataSheetRecords?.[0]?._id?.$oid);
    setShowAddNewDrawer(false);
  };

  const resetNewData = () => {
    setNewData({} as DataSheetRecord);
    setShowAddNewDrawer(false);
  };

  const handleFinishSaving = async (isSubmitting: boolean) => {
    setDataSheetRecords(surveyStore.currentStepData.submissions);
    if (
      surveyStore.currentStep === surveyStore.surveySteps?.length - 1 &&
      isSubmitting
    ) {
      await completeSurvey();
    } else {
      message.success("Form data was saved successfully!");
      if (state && (state as any).taskDeploymentId) {
        await handleUpdateTaskDeployment("In Progress");
      }
      surveyStore.currentStep !== surveyStore.surveySteps?.length - 1 &&
        onClickNext();
    }
  };

  const saveRecord = async (isSubmitting: boolean) => {
    surveyStore.changeSurveyData("", "fieldsStatus");
    await surveyStore
      .saveDataSheetRecords()
      .then(() => handleFinishSaving(isSubmitting))
      .catch(() => message.error("Error while saving record!"));
  };

  const createColumns = () => {
    const columns: {
      title: JSX.Element | string;
      dataIndex: string;
      width: string;
      key?: string;
      align?: string;
      render: (_: any, record: DataSheetRecord) => JSX.Element;
    }[] = filteredSchemas
      ?.map((schema) => {
        const dataColumn = {
          title: (
            <>
              {schema?.required && (
                <span style={{ color: "red", marginRight: 4 }}>*</span>
              )}{" "}
              {schema.display_name}
            </>
          ),
          dataIndex: schema.entity_name,
          key: schema.entity_name,
          width: "10%",

          render: (_: any, record: DataSheetRecord) => {
            let entityNameCheck = "";

            if (
              record[record.sheet_name][schema.entity_name + "_actual_value"]
            ) {
              entityNameCheck = schema.entity_name + "_actual_value";
            } else {
              entityNameCheck = schema.entity_name;
            }

            return (
              <DynamicCell
                disabledFields={disabledFields}
                key={schema.entity_name}
                schema={
                  schema.unit_id
                    ? ({
                        ...schema,
                        entity_name: entityNameCheck,
                      } as SheetSchema)
                    : schema
                }
                record={record}
                rowData={surveyStore.currentStepInfo}
                recordId={record._id?.$oid || record.key!}
                onSave={handleSave}
                activeNoteRecord={activeNoteRecord}
              />
            );
          },
        };
        if (schema.unit_id) {
          const unitColumn = {
            title: schema.display_name + " Unit",
            dataIndex: schema.entity_name + "_unit_id",
            key: schema.entity_name + "_unit_id",
            width: "10%",
            render: (_: any, record: DataSheetRecord) => (
              <DynamicCell
                disabledFields={disabledFields}
                key={schema.entity_name + "_unit_id"}
                schema={schema}
                record={record}
                rowData={surveyStore.currentStepInfo}
                recordId={record._id?.$oid || record.key!}
                isUnitId
                onSave={handleSave}
                activeNoteRecord={activeNoteRecord}
              />
            ),
          };
          return [dataColumn, unitColumn];
        }
        return dataColumn;
      })
      .flat();
    columns?.length > 0 &&
      columns?.push({
        title: "Actions",
        dataIndex: "action",
        width: "50px",
        align: "right",
        render: (_: any, record: DataSheetRecord) => {
          return (
            <Row justify="end">
              <Dropdown
                overlay={() => moreButtons(record)}
                trigger={["click"]}
                placement="bottomRight"
              >
                <StyledButton>
                  <EllipsisOutlined />
                </StyledButton>
              </Dropdown>
            </Row>
          );
        },
      });
    const warningColumn = {
      title: "",
      dataIndex: "action",
      width: "50px",
      render: (_: any, record: DataSheetRecord) => {
        return (
          <>
            {record.statusReasons?.find(
              (item) => item.review_status === RecordStatus.DENIED
            ) && (
              <Popover
                overlayClassName="popoverContent"
                title={
                  <FormWrapper>
                    {record.statusReasons
                      ?.filter((v) => v.review_status === RecordStatus.DENIED)
                      .map((v) => (
                        <Row
                          justify="space-between"
                          align="middle"
                          key={v.key}
                          gutter={[8, 8]}
                        >
                          {v?.comments?.length
                            ? v.comments
                            : "No reason specified"}
                        </Row>
                      ))}
                  </FormWrapper>
                }
              >
                <WarningOutlined className={styles.warning} />
              </Popover>
            )}
          </>
        );
      },
    };
    dataSheetRecords?.some((record) =>
      record?.statusReasons?.some(
        (v) => v.review_status === RecordStatus.DENIED
      )
    ) && columns.splice(0, 0, warningColumn);
    return columns;
  };

  const getFieldNotes = (): MultilineFieldNote[] => {
    return dataSheetRecords
      ?.map((record, index) =>
        record.field_notes?.map((item) => ({
          ...item,
          line: index + 1,
          recordId: record?._id?.$oid || "",
        }))
      )
      ?.flat()
      ?.filter(
        (note) =>
          note?.isAddedByAdmin &&
          surveyStore.currentStepInfo.display_fields?.includes(
            note?.field?.replace("_unit_id", "")
          ) &&
          !note?.isDeleted
      ) as MultilineFieldNote[];
  };

  const getCommentsTitle = () => {
    const fieldComments = getFieldNotes()?.filter(
      (note) => note?.noteType === "comment" || !note?.noteType
    )?.length;
    const fieldWarnings = getFieldNotes()?.filter(
      (note) => note?.noteType === "warning"
    )?.length;
    const result =
      fieldComments || fieldWarnings ? (
        <span className="text-align-center">
          <span className="color-white">You have </span>
          <span className="color-white">
            {fieldComments
              ? fieldComments +
                " comment" +
                (fieldComments > 1 ? "s" : "") +
                (fieldWarnings ? "," : "")
              : ""}
          </span>{" "}
          <span>
            {fieldWarnings
              ? fieldWarnings + " warning" + (fieldWarnings > 1 ? "s" : "")
              : ""}
          </span>
        </span>
      ) : (
        <></>
      );
    return result;
  };

  const handleClickNote = (note: MultilineFieldNote) => {
    setActiveNoteRecord({
      recordId: note?.recordId || "",
      noteType: note.noteType || "comment",
    });
    const lineNumber = note?.line;
    if (lineNumber && lineNumber > pagination.pageSize * pagination.current) {
      const pageNumber = Math.ceil(lineNumber / pagination.pageSize);
      setPagination((prev) => ({ ...prev, current: pageNumber }));
      document
        .getElementsByClassName("ant-table-row")
        [lineNumber - pageNumber * (pagination.pageSize - 1)]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
    } else if (
      lineNumber &&
      lineNumber <= pagination.pageSize * (pagination.current - 1)
    ) {
      const pageNumber = Math.ceil(lineNumber / pagination.pageSize);
      setPagination((prev) => ({ ...prev, current: pageNumber }));
      document
        .getElementsByClassName("ant-table-row")
        [
          pageNumber === 1
            ? lineNumber - 1
            : lineNumber - pageNumber * (pagination.pageSize - 1)
        ]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
    } else
      document
        .getElementsByClassName("ant-table-row")
        [lineNumber! - 1]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
  };

  const getFieldName = (field: string) => {
    const isUnitField = field?.endsWith("unit_id");
    const fieldName = field?.replace("_unit_id", "");
    const schemaField = surveyStore.dataSheet.sheet_schema?.find(
      (item) => item.entity_name === fieldName
    );
    const newName = isUnitField
      ? schemaField?.display_name + " Unit"
      : schemaField?.display_name;
    return newName;
  };

  const getCommentsText = () => {
    let fieldComments: any[] = [];
    getFieldNotes()
      ?.filter((note) => note?.noteType === "comment" || !note?.noteType)
      .forEach((note) => {
        const index = fieldComments?.findIndex(
          (item) => note?.line === item?.line
        );
        if (index === -1) {
          fieldComments.push({
            line: note.line,
            recordId: note.recordId,
            fields: [note.field],
            noteType: "comment",
          });
        } else {
          fieldComments[index].fields.push(note.field);
        }
      });
    let fieldWarnings: any[] = [];
    getFieldNotes()
      ?.filter((note) => note?.noteType === "warning")
      .forEach((note) => {
        const index = fieldWarnings?.findIndex(
          (item) => note?.line === item?.line
        );
        if (index === -1) {
          fieldWarnings.push({
            line: note.line,
            recordId: note.recordId,
            fields: [note.field],
            noteType: "warning",
          });
        } else {
          fieldWarnings[index].fields.push(note.field);
        }
      });
    return (
      <>
        {!!fieldComments?.length && (
          <Row className={styles.notesBlock}>
            <Col span={24} className={styles.comments}>
              <CommentOutlined className={styles.commentsIcon} /> Comments
            </Col>
            <Col span={24}>
              {fieldComments.map((note) => (
                <Row
                  className={styles.commentsText}
                  key={note.recordId}
                  onClick={() => handleClickNote(note)}
                  style={{ cursor: "pointer" }}
                >
                  <u>
                    Line {note.line}:{" "}
                    {note.fields
                      .map((field: string) => getFieldName(field))
                      .join(", ")}
                  </u>
                </Row>
              ))}
            </Col>
          </Row>
        )}
        {!!fieldWarnings?.length && (
          <Row className={styles.notesBlock}>
            <Col span={24} className={styles.warning}>
              <WarningOutlined className={styles.warningIcon} /> Warnings
            </Col>
            <Col span={24} className={styles.warning}>
              {fieldWarnings.map((note) => {
                return (
                  <Row
                    className={styles.commentsText}
                    key={note.recordId}
                    onClick={() => handleClickNote(note)}
                    style={{ cursor: "pointer" }}
                  >
                    <u>
                      Line {note.line}:{" "}
                      {note.fields
                        .map((field: string) => getFieldName(field))
                        .join(", ")}
                    </u>
                  </Row>
                );
              })}
            </Col>
          </Row>
        )}
      </>
    );
  };

  const viewInformationButton = size && size?.width <= 900 && (
    <Col flex={"100%"}>
      <Button
        style={{
          color: " #fff",
          borderColor: "#fff",
          width: "100%",
          height: "40px",
        }}
        type="ghost"
        onClick={openInfoDrawer}
        className={`margin-5 ${styles.addNewButton}`}
      >
        <QuestionCircleOutlined /> View Information & Progress
      </Button>
    </Col>
  );

  return (
    <>
      <NotesDrawer
        visible={isNotesDrawerShown}
        data={["Add Note"]}
        loading={false}
        notes={notes || []}
        onClose={hideNotesDrawer}
        onSubmit={(note) => handleAddNote(note)}
        incomingCurrentTab={"comment"}
        isFieldNote={false}
        onUpdateNotes={(notes: Note[]) => {
          const parsedFieldNotes =
            notes.map((note) => ({
              user: note.user,
              date: note.date,
              text: note.text,
              isDeleted: note.isDeleted,
            })) || [];

          const sheetType =
            data && data.sheet_type !== "new" ? "edited" : "new";

          const newRecord = {
            ...(data || ({} as DataSheetRecord)),
            sheet_type: sheetType,
            notes: parsedFieldNotes,
          };
          const newArray = dataSheetRecords.map((rec) => {
            if (rec?.key === newRecord?.key) {
              return newRecord;
            } else return rec;
          });
          setDataSheetRecords(newArray as DataSheetRecord[]);
          setNotes([...parsedFieldNotes]);
        }}
      />
      {showDataSheetFilesModal && (
        <SheetFilesModal
          visible={showDataSheetFilesModal}
          rowData={data}
          onClose={() => setShowDataSheetFilesModal(false)}
          handleSave={handleSave}
        />
      )}
      <Spin
        spinning={surveyStore.surveyLoading || surveyStore.surveySubmitting}
      >
        {surveyStore.dataSheet?._id ? (
          <div className={styles.formWrap}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Title
                  className="color-white"
                  level={3}
                  style={{ textAlign: "center" }}
                >
                  {surveyStore.dataSheet?.sheet_name}
                </Title>
                <Row gutter={[8, 8]} style={{ margin: "20px 150px" }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: surveyStore.dataSheet?.description,
                    }}
                  ></div>
                </Row>
              </Col>
              {!!getFieldNotes()?.filter(
                (note) => note?.isAddedByAdmin && !note?.isDeleted
              )?.length && (
                <Row
                  justify="center"
                  align="middle"
                  className={styles.commentsBox}
                >
                  <Col
                    className={styles.warning}
                    onClick={() => toggleAllComments()}
                  >
                    {!!getFieldNotes()?.filter(
                      (note) => note?.isAddedByAdmin && !note?.isDeleted
                    )?.length && (
                      <div className="text-align-center">
                        {getCommentsTitle()}
                        {isAllCommentsShown ? (
                          <UpOutlined className={styles.warning} />
                        ) : (
                          <DownOutlined className={styles.warning} />
                        )}
                      </div>
                    )}
                  </Col>
                  {isAllCommentsShown && getCommentsText()}
                </Row>
              )}

              <Row style={{ width: "100%" }} justify="end">
                {viewInformationButton}
                <Col flex={size && size?.width <= 900 ? "100%" : "none"}>
                  <StyledButton
                    style={{ textAlign: "center" }}
                    type="custom"
                    className={`margin-5 ${styles.addNewButton}`}
                    icon={<PlusOutlined />}
                    onClick={onClickAddNewRecord}
                    disabled={
                      !filteredSchemas?.length ||
                      surveyStore.surveyInfo?.status === "INACTIVE"
                    }
                  >
                    Add New
                  </StyledButton>
                </Col>
                <Col
                  flex={size && size?.width <= 900 ? "100%" : "none"}
                  className={styles.massUploadButtons}
                >
                  {surveyStore.currentStepInfo?.multiline_form_flag &&
                    size &&
                    size?.width >= 900 &&
                    surveyStore.currentStepInfo?.mass_upload_status &&
                    surveyStore.dataSheet && (
                      <MassUploadDataSheet
                        disabledUpload={
                          surveyStore.surveyInfo?.status === "INACTIVE"
                        }
                        defaultMetaDataRecord={
                          surveyStore.defaultMetaDataRecord
                        }
                        dataSheet={surveyStore.dataSheet}
                        taskDeploymentId={surveyStore?.taskDeploymentId}
                        onFinishUploadInsertDataFile={() => {}}
                        type="survey"
                        surveyId={surveyStore.surveyId}
                        currentStep={surveyStore.currentStep}
                        showUploadExcelButton={true}
                        showSampleExcelButton={true}
                        rowData={surveyStore.currentStepInfo}
                      />
                    )}
                </Col>
              </Row>
              <Col span={24}>
                <Divider
                  style={{ backgroundColor: "#3A3C5A", marginTop: "15px" }}
                />
              </Col>
            </Row>

            {filteredSchemas?.length > 0 || surveyStore.surveyLoading ? (
              <CommonTable
                loading={
                  surveyStore.surveyLoading || surveyStore.surveySubmitting
                }
                rowClassName={(row: DataSheetRecord) => {
                  const isValidationIssuesId =
                    row?._id?.$oid === validationIssuesId ||
                    row?.key === validationIssuesId;
                  const isActiveNoteRecord =
                    row?._id?.$oid === activeNoteRecord.recordId ||
                    row?.key === activeNoteRecord.recordId;
                  return isValidationIssuesId || isActiveNoteRecord
                    ? "highlight-row"
                    : "editable-row";
                }}
                columns={createColumns()}
                dataSource={dataSheetRecords}
                rowKey={(data: any) => data?._id?.$oid || data?.key}
                className="tableContiner"
                pagination={pagination}
                onChange={handleTableChange}
                scroll={{ x: true }}
              />
            ) : (
              <Row justify="center">
                <Title level={5}>No fields to fill</Title>
              </Row>
            )}
            <SurveyFooterButtons
              onClickPrevious={onClickPrevious}
              onClickSave={saveRecord}
              onClickNext={onClickNext}
            />
          </div>
        ) : (
          <div className={styles.formWrap}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Title
                  className="color-white"
                  level={3}
                  style={{ textAlign: "center" }}
                >
                  Sorry, data for this step was deleted
                </Title>
              </Col>
            </Row>
            {viewInformationButton}
            <SurveyFooterButtons
              onClickPrevious={onClickPrevious}
              onClickSave={saveRecord}
              onClickNext={onClickNext}
            />
          </div>
        )}
      </Spin>
      <Drawer
        className={`drawerCustom ${styles.drawer}`}
        onClose={() => {
          setShowAddNewDrawer(false);
          onClickDeleteBtn(newData);
        }}
        visible={showAddNewDrawer}
        placement="right"
      >
        <Col
          style={{
            height: "100%",
          }}
        >
          <FormWrapper
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Form layout="vertical">
              <Title className="color-white" level={4}>
                Add new
              </Title>{" "}
              <div>
                {filteredSchemas?.map((schema) => {
                  if (schema.unit_id) {
                    return (
                      <div key={schema.entity_name + "_unit_id"}>
                        <Form.Item
                          label={schema.display_name}
                          required={schema?.required}
                        >
                          <div className={styles.dynamicCell}>
                            <DynamicCell
                              disabledFields={disabledFields}
                              key={schema.entity_name}
                              schema={
                                {
                                  ...schema,
                                  entity_name:
                                    schema.entity_name + "_actual_value",
                                } as SheetSchema
                              }
                              record={dataSheetRecords[0]}
                              rowData={surveyStore.currentStepInfo}
                              recordId={
                                dataSheetRecords?.[0]?._id?.$oid ||
                                dataSheetRecords?.[0]?.key!
                              }
                              onSave={handleAddRecord}
                              activeNoteRecord={activeNoteRecord}
                              size={size}
                            />
                          </div>
                        </Form.Item>
                        <Form.Item
                          label={schema.display_name + " Unit"}
                          required={schema?.required}
                        >
                          <div className={styles.dynamicCell}>
                            <DynamicCell
                              disabledFields={disabledFields}
                              key={schema.entity_name + "_unit_id"}
                              schema={schema}
                              record={dataSheetRecords[0]}
                              rowData={surveyStore.currentStepInfo}
                              recordId={
                                dataSheetRecords?.[0]?._id?.$oid ||
                                dataSheetRecords?.[0]?.key!
                              }
                              isUnitId
                              onSave={handleAddRecord}
                              activeNoteRecord={activeNoteRecord}
                              size={size}
                            />
                          </div>
                        </Form.Item>
                      </div>
                    );
                  } else {
                    return (
                      <div key={schema.entity_name}>
                        <Form.Item
                          label={schema.display_name}
                          required={schema?.required}
                        >
                          <div className={styles.dynamicCell}>
                            <DynamicCell
                              disabledFields={disabledFields}
                              key={schema.entity_name}
                              schema={schema}
                              record={dataSheetRecords[0]}
                              rowData={surveyStore.currentStepInfo}
                              recordId={
                                dataSheetRecords?.[0]?._id?.$oid ||
                                dataSheetRecords?.[0]?.key!
                              }
                              onSave={handleAddRecord}
                              activeNoteRecord={activeNoteRecord}
                              size={size}
                            />
                          </div>
                        </Form.Item>
                      </div>
                    );
                  }
                })}
                <Row justify="end">
                  <StyledButton
                    type="default"
                    onClick={() => onClickDataSheetFileBtn(dataSheetRecords[0])}
                  >
                    <UploadOutlined /> Upload file
                  </StyledButton>

                  <StyledButton
                    type="default"
                    onClick={() => onClickNotesBtn(dataSheetRecords[0])}
                  >
                    <EditOutlined /> Add notes
                  </StyledButton>
                </Row>
              </div>
            </Form>
            <Row justify="end">
              <StyledButton
                hoverbgcolor="transparent"
                bgcolor="transparent"
                bordercolor="transparent"
                color="white"
                type="custom"
                onClick={resetNewData}
              >
                <span>Cancel</span>
              </StyledButton>
              <StyledButton
                className="margin-5"
                type="custom"
                onClick={saveNewRecord}
              >
                <span>Add data</span>
              </StyledButton>
            </Row>
          </FormWrapper>
        </Col>
      </Drawer>
    </>
  );
});
