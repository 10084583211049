import { evaluate } from "mathjs";
import {
  comparisonOperators,
  mathSymbolsOperators,
} from "../../../services/mongoOperators";
import { message } from "antd";

export const generateEditableCell = (
  dynamicColums: any[],
  editingKey: string,
  lastRevisionData: any
) => {
  const mergedColumns: any = [];
  try {
    return (
      dynamicColums &&
      dynamicColums?.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          sorter: (a: any, b: any) => {
            switch (col?.dataType) {
              case "number":
                return (
                  +a[a?.sheet_name][col?.dataIndex] -
                  +b[b?.sheet_name][col?.dataIndex]
                );
              case "date":
                return Date.parse(a[a?.sheet_name][col?.dataIndex]) >
                  Date.parse(b[b?.sheet_name][col?.dataIndex])
                  ? 1
                  : 0;
              default:
                return (
                  a[a?.sheet_name][col?.dataIndex] ||
                  "".localeCompare(b[b?.sheet_name][col?.dataIndex] || "")
                );
            }
          },
          onCell: (record: DataSheetRecord) => {
            return {
              ...col,
              record,
              editing:
                (record?._id?.$oid ? record?._id?.$oid : record?.key) ===
                editingKey,
              lastRevisionData,
            };
          },
        };
      })
    );
  } catch (error) {
    return mergedColumns;
  }
};

export const isAirDistanceSheet = (dataSheet?: DataSheet) => {
  const requiredFields = ["destination_city", "origination_city", "distance", "routing"];
  const entityNames = dataSheet?.sheet_schema?.map((v: SheetSchema) => v.entity_name);

  const hasRequiredFields = requiredFields.every((field) => entityNames?.includes(field));
  const hasJourneyField = entityNames?.includes("type_of_journey") || entityNames?.includes("journey_type");

  return hasRequiredFields && hasJourneyField;
};

const getDividedArray = (array: any[]) => {
  return array.reduce((newArray, item) => {
    if (
      comparisonOperators.some((v) => v.label === item) ||
      item === "==" ||
      item === "!="
    ) {
      newArray.push([item]);
    } else {
      const lastSublist = newArray[newArray.length - 1];
      if (
        lastSublist &&
        !(
          comparisonOperators.some((v) => v.label === lastSublist[0]) ||
          lastSublist[0] === "==" ||
          lastSublist[0] === "!=="
        )
      ) {
        lastSublist.push(item);
      } else {
        newArray.push([item]);
      }
    }
    return newArray;
  }, []);
};

const checkValidation = (rule: (string | number)[]) => {
  const expressions: (number | string)[][] = getDividedArray(rule);
  const newExpressions = expressions.map((expression) => {
    const newExpression = expression.join(" ");
    if (expression?.length > 2) {
      const updatedExpression = evaluate(newExpression);
      return [updatedExpression];
    } else {
      return expression;
    }
  });
  const expressionString = newExpressions.flat().join(" ");
  const result = evaluate(expressionString);
  return result;
};

const getOperator = (operator: string) => {
  return operator === "$eq"
    ? "=="
    : operator === "$ne"
    ? "!="
    : [...comparisonOperators, ...mathSymbolsOperators].find(
        (v) => operator === v.value
      )?.label;
};

export const areValidFieldsByRules = (
  validationRules: ValidationRule[],
  recordData: { [key: string]: any },
  setFields: (fields: any[]) => void,
  isForm = true,
  isMessageShown = true
) => {
  let isEmptyMessage = false;
  const validation = validationRules.map((validationRule) => {
    const { errorMessage, rule } = validationRule;
    const newArray = rule.map((item) => {
      switch (item.type) {
        case "column":
          return recordData[item.value];
        case "operator":
          return getOperator(item.value);
        case "number":
          return item.value;
      }
    });
    const areFieldsFilled = newArray.every((item) => !!item?.toString().length);
    if (!areFieldsFilled) {
      isEmptyMessage = true;
      return true;
    }
    const res = checkValidation(newArray);
    if (!res && isMessageShown) {
      message.error(errorMessage);
    }
    return res;
  });
  if (isEmptyMessage && isMessageShown) {
    message.error("Some rules were not validated due to missing some fields");
  }

  const isValid = validation.every((item) => item);
  const errorFields = [
    ...new Set(
      validation
        .map((item, index) => {
          if (!item) {
            return validationRules[index].rule
              .filter((v) => v.type === "column")
              .map((v) => {
                return {
                  fieldName: v.value,
                  errorMessage: validationRules[index].errorMessage,
                };
              });
          }
          return;
        })
        .flat()
        ?.filter((v) => v)
    ),
  ];

  !!errorFields?.length &&
    (isForm
      ? setFields(
          errorFields.map((field) => ({
            name: [field?.fieldName as string],
            errors: ["This field is not valid"],
            errorMessage: field?.errorMessage,
          }))
        )
      : setFields(errorFields));
  return isValid;
};
