import React, { useState } from "react";
import {
  FileOutlined,
  EyeOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  MoreOutlined,
  LineChartOutlined,
  FundOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useBoolean } from "ahooks";
import {
  Tooltip,
  Typography,
  Row,
  Drawer,
  Space,
  Col,
  Dropdown,
  Menu,
  Badge,
  Progress,
  Spin,
} from "antd";
import { purple } from "@ant-design/colors";
import {
  CommonTable,
  StyledButton,
  StyledDataSheetCollapse,
  TableCommonMenu,
  CardsWrap,
} from "../../../../shared/commonStyles";
import { stripHtmlTags } from "../../../../shared/commonFunctions";
import MassUploadDataSheet from "./MassUploadDataSheet";
import { useNavigate } from "react-router";
import { useCheckRolesPermissions } from "../../../../hooks/useCheckRolesPermissions";
import { encodeUrlName } from "../../../../shared/commonFunctions";

import { Collapse } from 'antd';
import { DataSheetCardDescription } from "./DataSheetCardDescription";

const { Title } = Typography;


const DataSheetIcon = () => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{ marginRight: "8px" }} // Optional styling
  >
    <rect width="18" height="16" fill="url(#pattern0_4190_66245)" />
    <defs>
      <pattern
        id="pattern0_4190_66245"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use
          xlinkHref="#image0_4190_66245"
          transform="matrix(0.015625 0 0 0.0175781 0 -0.0625)"
        />
      </pattern>
      <image
        id="image0_4190_66245"
        width="64"
        height="64"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAABR9JREFUeJzt22vsHFMYx/HZVlU16lIhqIo79YYQlwhRERFFXKPaaASNa/oCIeGFkAgSiWpRhLhFqPv1BUkpqpJWqrRKGu0LpFpEabQubX28mPkn29kzszvbmZ39R7/vduac5zy/M2fPnPOcZ6JoG9sYVGBvfIgNeAOj6vapp+BZW3JP3T71DIxKnnwzqzC0W5tDynSwB1wYRdGI1LW9oig6rQZfeguGYIkw79XtX+Xg5gzxA1xSt4+VgcuxKSX439Tv9Ti7bl9LBUdgduBpr8WZ+Dt1fTMexf51+941GIlrMT9jqG/EhKTs1IwymzEHl2H7ujV1TCL+6wxR8CcuTNW5Xuvfo5l5qO9th6E4B1Mwuk3Z83OErMCRGfVOwZqcusdWo64NaOCdJkd+xL455U/MEbERd6afJobjEa2T4gCb1TUn4NSAQzPb1Lk3EZvFi9guKbsj5uaU/RM39EZtWMyTAad+xrA29fbANCzOEPageHS9lnH/E1yJXXqlNSRiR6zLcPCsDm00xPNH6P/9dODaCpxctbaOwMQM8fBCQVtj8V2OPfgCu1WlpzC2nPzSbMDOBe0dJ57QQqzHAVVpKQwOCDi7NvV7Whd2X87ogOlV6OgaPJRycCFuS11bieEF7Y7P6IBDq9JSGIzT+hqbIp7Z/0pdv6mg7SFa54IFVWkpDLbHZykHl0vW45iRurcB4wq2kd4WT6pGTRfgscDwPLfp/mj8mrr/lQKzt/jVOBVP4KJqlBQkcerugPiW1x0mB8rNV+eiZWvACDwVELUyS1RG+SU4sNf+bxU4HksDYn6WMzOLNzBzAvXWJcO7v4O14kXJi8I7sDU4pgMbo2RvZBaLV5K5e4aegr1wAz7PcBq+wUEFbA7H8zn2VmN6Jx1aCdgOk/Cu/KgLPI6RXbYzFX+0sb9MvJjavWydeY7lrecHWKiEHRjG4BnZ6/0BVmNMGfraOXRkjhOb8CZOR6Pkdg/BA1r3Ds3cXlZ7ebOtNnUbSf1SO6Aim92Btzr4CyzC+BLaGovnZMf2BliFfcrQ14lTQ3U+CT6Jnbps52rxnj6PZbhVm8hyZYgTE9q9Bpfj4AI2d8BLOfZW434cXaW2wuBYvCA8XH/S2UJoZ3yUIXwRLtZPC6EQSUeEjqx/0X4p/EGg3u+4Qr8vhZtJhvHjATErsWtGnXSaC3FQc1Afat4VEDU7UO7SQLl5CgZJ+xLMCohrDojsrnWBszRrpGS00cA14oDIxGqUdAmGYUFKYHNIbGbq3nocVrCNW1I2JlejpktwOP5JOTkFe2oNihY6txMHRb9P2eifoOgA4rO7LZzUGhb/VsHkBeHDVvopLB5FUYT9ta4c08HQbg5GXsnogP46GImiSo7GTpC9T1iv3+KJyj0c3Q8/5NiDL9W1TwihvOPx88TL62b+FT4eX6mEnWlp6D5BYk/5CRIzks7JmhPqT5BIhHSTInOf/BSZ2ZLkaPEoez+n7Abc2Bu1YTENvN3k0Cr5SVIn5YjZiDuEk6Qelp8kVV/ugDiocrZ4MZR7BogLcjpgBY7KqDdefprccdWoKxntEyX/wsWpOtPkR5I/Nsi21yNxHT7NELQJ5yRlr8ooMzhTZdOIk6VDJ0e/YYJwsvQsgzmuECJ5kp2ky0+o29fKwE0Zw32A/ooNlI3/+yczURRF4oBpiDPq9q0nCH82t1qSPN0Ng+f9GEVRo9FYF0XRq6nLTzUajU11+FML4hOqucms/7r/26ez2yiZ/wAt2IEQNM/rTwAAAABJRU5ErkJggg=="
      />
    </defs>
  </svg>
);

const SheetSchemaDatatable = ({
  dataSheet,
  onFinishUploadInsertDataFile,
  onClickExportExcel,
  isDisabled,
  showCheckingModal,
  metaDataRecords,
  deletedCount,
  pendingCount,
  deniedCount,
  totalCount,
  exportLoader,
  setExportLoader,
  isPending,
  identifierColumn,
  filters,
  allFields,
}) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const { checkRolesPermission } = useCheckRolesPermissions();
  const [isAllDescVisible, { toggle: toggleAllDescVisible }] = useBoolean(false);

  const columns = [
    {
      title: "Display Name",
      dataIndex: "display_name",
      width: "28%",
      editable: false,
    },
    {
      title: "Entity Name",
      dataIndex: "entity_name",
      width: "27%",
      editable: false,
    },
    {
      title: "Entity Unit",
      dataIndex: "unit_id",
      width: "15%",
      editable: false,
    },
    {
      title: "Data Type",
      dataIndex: "data_type",
      width: "15%",
      editable: false,
      render: (record) => {
        if (record === "number") {
          return "Number";
        } else if (
          record === "string" ||
          record === "text" ||
          record === "rich-text"
        ) {
          return "Text";
        } else if (record === "date") {
          return "Date";
        } else {
          return "";
        }
      },
    },
    {
      title: "Required",
      dataIndex: "required",
      width: "15%",
      editable: false,
      render: (record) => {
        return record ? (
          <Tooltip title={"Yes"}>
            <CheckCircleOutlined style={{ fontSize: 20, color: "#52c41a" }} />
          </Tooltip>
        ) : (
          <Tooltip title={"No"}>
            <CloseCircleOutlined style={{ fontSize: 20 }} />
          </Tooltip>
        );
      },
    },
  ];

  const onClickPanelHeader = (event) => {
    try {
      event.stopPropagation();
    } catch (e) {
      console.log("panel header clicked", e);
    }
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const moreButtons = (
    <TableCommonMenu>
      <Menu.Item key="data-schema">
        <StyledButton type="custom" onClick={showDrawer}>
          View Data Schema
        </StyledButton>
      </Menu.Item>
      <Menu.Item key="deleted">
        <StyledButton
          type="custom"
          onClick={() =>
            navigate(
              `/data/data-sheets/${dataSheet?._id?.$oid}/${encodeUrlName(
                dataSheet.sheet_name.replace("/", " ")
              )}/documents/deleted`
            )
          }
        >
          View Deleted Items
          <Badge
            className="margin-5"
            count={deletedCount}
            showZero
            style={{ backgroundColor: "#A68DFB" }}
          />
        </StyledButton>
      </Menu.Item>
      <Menu.Item key="pending">
        <StyledButton
          type="custom"
          onClick={() =>
            navigate(
              `/data/data-sheets/${dataSheet?._id?.$oid}/${encodeUrlName(
                dataSheet.sheet_name.replace("/", " ")
              )}/documents/pending`
            )
          }
        >
          View Pending Items
          <Badge
            className="margin-5"
            count={pendingCount}
            showZero
            style={{ backgroundColor: "#A68DFB" }}
          />
        </StyledButton>
      </Menu.Item>
      <Menu.Item key="denied">
        <StyledButton
          type="custom"
          onClick={() =>
            navigate(
              `/data/data-sheets/${dataSheet?._id?.$oid}/${encodeUrlName(
                dataSheet.sheet_name.replace("/", " ")
              )}/documents/denied`
            )
          }
        >
          View Denied Items
          <Badge
            className="margin-5"
            count={deniedCount}
            showZero
            style={{ backgroundColor: "#A68DFB" }}
          />
        </StyledButton>
      </Menu.Item>
      {metaDataRecords?.length > 0 && (
        <Menu.Item key="missing">
          <StyledButton type="custom" onClick={showCheckingModal}>
            Check missing records
          </StyledButton>
        </Menu.Item>
      )}
    </TableCommonMenu>
  );

  const exportButtons = (
    <TableCommonMenu>
      <Menu.Item key="report">
        <StyledButton
          type="custom"
          icon={<FileOutlined />}
          onClick={onClickExportExcel}
          disabled={isDisabled}
          loading={exportLoader}
        >
          Export Excel
        </StyledButton>
      </Menu.Item>
      <Menu.Item key="template">
        <MassUploadDataSheet
          dataSheet={dataSheet}
          type="dataSheet"
          onFinishUploadInsertDataFile={onFinishUploadInsertDataFile}
          showUploadExcelButton={false}
          showSampleExcelButton={true}
          identifierColumn={identifierColumn}
          setLoaderButton={setExportLoader}
        />
      </Menu.Item>
    </TableCommonMenu>
  );

  const handleClickEditDataSheet = () => {
    const sheetId = dataSheet?.["_id"]?.["$oid"];
    if (sheetId) {
      navigate(
        `/data/data-sheets/${sheetId}/${encodeUrlName(
          dataSheet?.sheet_name.replace("/", " ")
        )}/update`,
        { state: { type: "detailedPage" } }
      );
    }
  };

  return (
    <>
      <StyledDataSheetCollapse
        style={{ marginBottom: "30px"}}
        ghost
        collapsible="header"
        defaultActiveKey={null}
      >
        <Collapse.Panel
          header={
            <span>
              <strong>
                <DataSheetIcon />
                Summarize Data Sheet Explanation
              </strong>
            </span>
          }
          style={{ padding: "2px 10px" }}
        >
          <Spin style={{display: "flex", justifyContent: "center"}} spinning={allFields.length === 0} />
          <div style={{overflowX: "auto"}}>
            <CardsWrap style={{width: "fit-content", flexWrap: "nowrap", webkitFlexWrap: "nowrap"}}>
              {allFields && allFields.map((field, index) => (
                <DataSheetCardDescription
                  key={index}
                  title={field["Name of Field"]}
                  description={field["Description"]}
                />
              ))}

            </CardsWrap>
          </div>
        </Collapse.Panel>
      </StyledDataSheetCollapse>
      <Drawer
        className="drawerCustom"
        title="Data Sheet Schema"
        width={700}
        onClose={onClose}
        visible={visible}
        placement="right"
        footerStyle={{ background: "#362e4b", borderTop: "none" }}
        footer={
          <Space>
            <StyledButton
              className="margin-5"
              type="custom"
              hovercolor="#A68DFB"
              hoverbgcolor="transparent"
              bgcolor="transparent"
              bordercolor="transparent"
              color="#fff"
              onClick={onClose}
            >
              Close
            </StyledButton>
            <StyledButton
              type="custom"
              className="color-white"
              onClick={onClose}
            >
              OK
            </StyledButton>
          </Space>
        }
      >
        <Row
          style={{ background: "#2D273F" }}
          justify="space-between"
          onClick={(event) => onClickPanelHeader(event)}
        >
          <Title className="color-white" level={4}>
            Data Sheet Schema
          </Title>
        </Row>

        <CommonTable
          loading={false}
          rowKey="id"
          dataSource={dataSheet?.sheet_schema}
          columns={columns}
          scroll={{ x: true }}
          pagination={false}
        />
      </Drawer>
      {dataSheet ? (
        <>
          <Row justify="space-between">
            <style jsx="true">
              {`
                .upload-list-inline {
                  display: inline-block;
                }
              `}
            </style>
            <Row style={{ width: "40%", alignItems: "baseline" }}>
              <Title className="color-white" level={4}>
                {dataSheet?.sheet_name}{" "}
              </Title>
              {""}
              <span
                style={{
                  color: " #C1BFCD",
                  fontSize: "16px",
                  marginLeft: "5px",
                }}
              >
                ({totalCount} records)
              </span>
              {""}
              <Progress
                percent={dataSheet.qualityScore?.percent}
                steps={3}
                strokeColor={[purple[2], purple[3], purple[4]]}
                trailColor="#4E4268"
                showInfo={false}
                style={{ margin: "0 10px" }}
              />
              {""}
              <span className="color-white" style={{ fontSize: "16px" }}>
                {dataSheet.qualityScore?.label || "N/A"}
              </span>
            </Row>
            <Col>
              {!isPending && !checkRolesPermission(["role:elevateduser"]) && (
                <StyledButton
                  className="margin-5"
                  type="custom"
                  icon={<EditOutlined />}
                  onClick={handleClickEditDataSheet}
                >
                  Data Sheet Settings
                </StyledButton>
              )}
              <Dropdown overlay={exportButtons} trigger={["click"]}>
                <StyledButton
                  type="custom"
                  className="margin-5"
                  icon={<FileOutlined />}
                  loading={exportLoader}
                >
                  Export
                </StyledButton>
              </Dropdown>
              {dataSheet?.is_standard_data && (
                <StyledButton
                  type="custom"
                  className="margin-5"
                  icon={<FundOutlined />}
                  disabled={isDisabled}
                  onClick={() =>
                    navigate(
                      `/data/data-sheets/${
                        dataSheet?._id?.$oid
                      }/${encodeUrlName(
                        dataSheet.sheet_name.replace("/", " ")
                      )}/documents/generate-report`,
                      {
                        state: {
                          dataLakeMapReport: [],
                          report_period: null,
                          location_id: null,
                          business_unit_id: null,
                          standardSheet: dataSheet,
                          filters,
                        },
                      }
                    )
                  }
                >
                  Generate Report
                </StyledButton>
              )}
              {!isPending && (
                <>
                  <MassUploadDataSheet
                    dataSheet={dataSheet}
                    type="dataSheet"
                    onFinishUploadInsertDataFile={onFinishUploadInsertDataFile}
                    showUploadExcelButton={true}
                    showSampleExcelButton={false}
                  />
                  {!checkRolesPermission(["role:elevateduser"]) && (
                    <Dropdown overlay={moreButtons} trigger={["click"]}>
                      <StyledButton
                        type="custom"
                        className="margin-5"
                        icon={<MoreOutlined />}
                      >
                        More
                      </StyledButton>
                    </Dropdown>
                  )}
                </>
              )}
            </Col>
          </Row>
          <Row
            style={
              isAllDescVisible
                ? { flexDirection: "column", alignItems: "start" }
                : { alignItems: "center" }
            }
          >
            <p
              style={
                isAllDescVisible
                  ? {
                      width: "50%",
                      fontSize: "16px",
                      marginRight: "0px",
                      color: " #C1BFCD",
                    }
                  : {
                      width: "50%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      color: " #C1BFCD",
                      fontSize: "16px",
                      margin: "0px 5px 0px 0px",
                    }
              }
            >
              {isAllDescVisible ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: dataSheet.description,
                  }}
                ></div>
              ) : (
                stripHtmlTags(dataSheet.description)
              )}
            </p>{" "}
            {stripHtmlTags(dataSheet.description).length > 100 && (
              <StyledButton
                type="custom"
                onClick={() => toggleAllDescVisible()}
                hoverbgcolor="transparent"
                hovercolor="transparent"
                bgcolor="transparent"
                bordercolor="transparent"
                color="#A68DFB"
                height="10px"
                padding="0px"
                margin="0px"
              >
                {isAllDescVisible ? "Show less" : "Show more"}
              </StyledButton>
            )}
          </Row>
        </>
      ) : null}
    </>
  );
};

export default SheetSchemaDatatable;
