import { DataSheetWrapper } from '../../../../shared/commonStyles';
import React from 'react';


export const DataSheetCardDescription = ({
    title = "",
    description = "",
}) => {
    return (
        <DataSheetWrapper>
            <div>
                <p className="title">{title}</p>
                <p className="description">{description}</p>
            </div>
        </DataSheetWrapper>
    );
}