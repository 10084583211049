import {
  Alert,
  Col,
  Divider,
  Dropdown,
  Menu,
  Row,
  Typography,
  Upload,
} from "antd";
import {
  DownloadOutlined,
  DownOutlined,
  InboxOutlined,
  PlusCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  FormWrap,
  FormWrapper,
  StyledButton,
  TableCommonMenu,
} from "../../../shared/commonStyles";

import React, { useEffect, useState } from "react";
import path from "path";
import "./step2.scss";
import Progress from "antd/es/progress";

const { Title } = Typography;
const { Dragger } = Upload;

const step2 = ({
  isUser,
  files,
  selectOCRProfile,
  ocrProfile,
  setFiles,
  onClickNext,
  onClickPrevious,
  initialValues,
  state,
  isUploading,
  downloadFileHandler,
}) => {
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    let interval;
    if (isUploading) {
      interval = setInterval(() => {
        setPercent((prev) => {
          if (prev >= 86) {
            clearInterval(interval);
            return 86;
          }
          const randomIncrement = Math.floor(Math.random() * 4);
          return Math.min(prev + randomIncrement, 86);
        });
      }, 500);
    }

    return () => clearInterval(interval);
  }, [isUploading]);

  const beforeUpload = (file) => {
    setFiles([file]);
  };

  const onRemove = (file) => {
    const filesList = files.filter((v) => v.uid !== file.uid);
    setFiles(filesList);
  };

  const structured_files_types = `
    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
    text/csv, 
    `;

  const ocr_files_types = `
        image/png, 
        image/jpg, 
        image/jpeg, 
        image/JPG, 
        application/pdf,
    `;

  const downloadHandler = () => {
    downloadFileHandler();
  };

  const isOcrFileType = initialValues.source_file_type === "ocr_file";

  const moreButtons = 
    state?.dataSheet?.ocr_profiles && Array.isArray(state?.dataSheet?.ocr_profiles) ? (
      <TableCommonMenu>
        {state?.dataSheet?.ocr_profiles?.map((profile) => (
          <Menu.Item
            key={profile.profile_name}
            onClick={() => {
              selectOCRProfile(profile);
            }}
          >
            <StyledButton type="custom">{profile.profile_name}</StyledButton>
          </Menu.Item>
        ))}
      </TableCommonMenu>
    ) : (
      <></>
    );

  return !isUploading ? (
    <FormWrap
      width="572px"
      style={{
        marginTop: "50px",
        backgroundColor: "#2D273F",
        borderRadius: "4px",
      }}
    >
      <FormWrapper style={{ margin: "20px 0px" }}>
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Title level={3} style={{ fontWeight: "600" }}>
              Upload data
            </Title>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Title
              level={5}
              className="color-grey"
              style={{ margin: "10px 0px 20px 0px" }}
            >
              Upload file and filter the data you want to add to the system
            </Title>
          </Col>
        </Row>

        {state?.isOcrUpload && (
          <Dropdown overlay={moreButtons}>
            <StyledButton
              type="custom"
              loading={false}
              style={{ margin: "0 0 10px 0" }}
            >
              <span>
                {ocrProfile ? ocrProfile.profile_name : "Select AI Profile"}
              </span>
              <DownOutlined />
            </StyledButton>
          </Dropdown>
        )}

        {!state?.isOcrUpload && (
          <Row justify="space-between">
            <Col lg={24} md={24} sm={24} xs={24}>
              {!isOcrFileType && (
                <Alert
                  message="Warning! In your spreadsheet, you must format all date values to YYYY-MM-DD (eg. 2022-01-01). Failure to do so may result in data corruption."
                  type="warning"
                  showIcon
                />
              )}
            </Col>
            <Divider
              style={{ background: "#3A3C5A", margin: "5px 0px 25px 0px" }}
            />
          </Row>
        )}

        <Dragger
          multiple={false}
          customRequest={({ file, onSuccess }) => {
            onSuccess("ok");
          }}
          beforeUpload={beforeUpload}
          maxCount={1}
          fileList={files}
          onRemove={onRemove}
          disabled={state?.action}
          style={{
            backgroundColor: "#423A5A",
            color: "white",
            padding: "5px 25px",
            border: "1px solid #423a5a",
          }}
          accept={
            state?.isOcrUpload
              ? ".pdf"
              : initialValues.source_file_type === "structured_file" &&
                structured_files_types
          }
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined className="color-white" />
          </p>
          <p className="ant-upload-text color-white">
            Drag {state?.isOcrUpload ? "PDF" : "file"} to this area or click to
            upload
          </p>
          <p className="ant-upload-hint color-white">
            {state?.isOcrUpload
              ? "Supports a single upload of a PDF file."
              : "Supports a single upload. File type is restricted to the type of upload you have chosen."}
          </p>
        </Dragger>

        {/* ABLE TO DOWNLOAD FILE ON UPDATE TIME */}
        {state?.action === "update" && (
          <>
            <Row justify="end">
              <Col lg={24} md={24} sm={24} xs={24} style={{ textAlign: "end" }}>
                <StyledButton
                  type="custom"
                  hoverbgcolor="transparent"
                  bgcolor="transparent"
                  bordercolor="transparent"
                  color="white"
                  onClick={() => downloadHandler()}
                  style={{ marginRight: "0px" }}
                >
                  <DownloadOutlined />
                  <span>Download file</span>
                </StyledButton>
              </Col>
            </Row>
          </>
        )}
        {/* ABLE TO DOWNLOAD FILE ON UPDATE TIME */}
        <Row style={{ marginTop: "20px" }}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <StyledButton
              type="custom"
              onClick={() => onClickPrevious()}
              hoverbgcolor="transparent"
              bgcolor="transparent"
              bordercolor="transparent"
              color="white"
              style={{ padding: "11px 11px 11px 0px" }}
            >
              <span>Go back</span>
            </StyledButton>
            <StyledButton
              className="margin-5"
              loading={isUploading}
              onClick={() => onClickNext()}
              type="custom"
              disabled={
                (!initialValues?._id?.$oid && files?.length === 0) ||
                (state?.isOcrUpload ? !ocrProfile : false)
              }
            >
              {state?.isOcrUpload ? (
                <span>Upload PDF</span>
              ) : (
                <span>Continue to step {isUser ? "2" : "3"}</span>
              )}
            </StyledButton>
          </Col>
        </Row>
      </FormWrapper>
    </FormWrap>
  ) : (
    <div className="ocr-loading-container">
      <Progress type="circle" percent={percent} strokeColor="#a68dfb" />
      <h1>Uploading and Processing Your PDF</h1>
      <p>
        Your PDF file is currently being uploaded and processed. We're sending
        it to a large language model for analysis. Please hold on as we work on
        transforming your document—this process might take a little while, but
        we'll keep you updated on the progress.
      </p>
    </div>
  );
};

export default step2;
