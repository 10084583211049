import React, { useEffect, useState } from "react";
import Overview from "./Overview";

// Remove this later on once ready to be deployed to prod.
// Set the isProd variable based on the environment variable
const isProd = process.env.REACT_APP_REDIRECT_SIGNIN_ENV == 'NOTUSED_app.hydrus.ai/home';
import BreakdownPivotTable  from "./Components/pivotTable/BreakdownPivotTable";

import {
  Alert,
  Avatar,
  Badge,
  Button,
  Dropdown,
  Layout,
  Menu,
  Typography,
  message,
} from "antd";

import { Link, useLocation } from "react-router-dom";

import styles from "../../../components/Header.module.scss";
import { any } from "prop-types";

const OverviewWrapper = () => {

  const [selectedKey, setSelectedKey] = useState('charts');

  const handleClick = (e:any) => {
    console.log('click ', e);
    if (e.key == "charts") {
      setSelectedKey('charts');
    }
    if (e.key == "table") {
      setSelectedKey('table');
    }
    // You can handle the click event here, e.g., navigate to a different page or update the state
  };

  // Define the navigation items based on the environment variable
  const navs = [
    {
      key: "charts",
      label: "Charts",
    },
    // Only include the "Table" button if not in production
    ...(!isProd
      ? [{
          key: "table",
          label: "Table",
        }]
      : [])
  ];

  return (
    <>
      <Menu
          style={{ flex: "auto", minWidth: 0, marginBottom:"20px" }}
          mode="horizontal"
          theme="dark"
          items={navs}
          selectedKeys={[selectedKey]}
          className={styles.headerNav}
          onClick={handleClick}
      />
      {selectedKey === 'charts' &&
        <Overview/>
      }
      {selectedKey === 'table' &&
        <BreakdownPivotTable/>
      }
    </>
    
  );
};

export default OverviewWrapper;